import Head from 'next/head';

interface MetaProps {
	title: string;
	description: string;
	url: string;
	image?: string;
	siteName?: string;
	type?: string;
	locale?: string;
	alternateLangs?: { lang: string; href: string }[];
	schema?: string;
}

const MetaTags: React.FC<MetaProps> = ({
	title,
	description,
	image = process.env.NEXT_PUBLIC_WEB_URL + '/site-img.jpg',
	url,
	siteName = 'CreoPlay',
	type = 'website',
	locale,
	alternateLangs = [],
	schema,
}) => {
	const urlSite = process.env.NEXT_PUBLIC_WEB_URL + url;

	return (
		<Head>
			{/* Standard Meta */}
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no ,viewport-fit=cover"
			/>
			<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
			<meta name="apple-mobile-web-app-capable" content="yes" />

			{/* Open Graph Meta */}
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={image} />
			<meta property="og:url" content={urlSite} />
			<meta property="og:site_name" content={siteName} />
			<meta property="og:type" content={type} />
			{locale && <meta property="og:locale" content={locale} />}
			<meta property="og:image:width" content="1200" />
			<meta property="og:image:height" content="630" />

			{/* Twitter Meta */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={image} />

			{/* Canonical Link */}
			<link rel="canonical" href={urlSite} />

			{/* Alternate Language Links */}
			{alternateLangs.map(({ lang, href }) => (
				<link key={lang} rel="alternate" hrefLang={lang} href={href} />
			))}

			{schema && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schema }} />}
		</Head>
	);
};

export default MetaTags;
